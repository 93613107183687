import { Controller } from 'stimulus'
export default class extends Controller {
  static targets = ["file", "form"]
  connect() {
    console.log('admin-theme-upload connected')
  }
  
  launchUpload(e) {
    e.preventDefault()
    this.fileTarget.click()
  }
  
  upload(e) {
    e.preventDefault()
    this.formTarget.requestSubmit()
  }
}