import { Controller } from 'stimulus'
export default class extends Controller {
  static targets = ["popup"]
  connect() {    
    console.log('account-menu connected')
  }

  toggle() {    
    if (this.popupTarget.classList.contains('hidden')){
      this.popupTarget.classList.remove('hidden')
    }else{
      this.popupTarget.classList.add('hidden')
    }
  }
}