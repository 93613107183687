import { Controller } from "stimulus"
import tinymce from 'tinymce/tinymce'

export default class extends Controller {
  static targets = ["input"]

  initialize () {
    this.defaults = {
      base_url: '/' + 'tinymce',
      plugins:
        'preview importcss autolink autosave save directionality code visualblocks visualchars fullscreen image link media table charmap pagebreak nonbreaking anchor advlist lists help charmap quickbars',
      menubar: 'file edit view insert format tools table',
      toolbar:
        'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist preview | forecolor backcolor removeformat | pagebreak | charmap | insertfile image media link anchor | ltr rtl fullscreen',
      toolbar_sticky: true,
      suffix: '.min',
      //skin: (window.matchMedia('(prefers-color-scheme: dark)').matches ? 'oxide-dark' : 'oxide'),
      //content_css: (window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'default')
      content_css: '/custom_css/editor/content.css'    
    }
  }

  connect(){
    console.log('tinymce connect ed')
    let config = Object.assign({ target: this.inputTarget }, this.defaults)    
    tinymce.init(config)
  } 
  
  disconnect () {
    //tinymce.remove()
  }
}