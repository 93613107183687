import { Controller } from 'stimulus'
export default class extends Controller {
  static targets = ["form"]
  connect() {    
  }

  submit(e) {
    e.preventDefault()
    let token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')

    //this.formTarget.submit()
    fetch(this.formTarget.action, {
      method: 'post',
      headers: {
        'X-CSRF-Token': token
      },
      body: new URLSearchParams(new FormData(this.formTarget))
    }) 
  }
}