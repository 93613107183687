import { createApp } from 'vue'

import React from 'react'
import { createRoot } from 'react-dom/client';

import Hello from '../components/Hello.vue'
import ImageUploader from '../components/ImageUploader.vue'

const components = {Hello, ImageUploader}


import PageCollection from '../react_components/PageCollection.jsx'
import Tagging from '../react_components/Tagging.jsx'
import ImageGeneratorModal from '../react_components/modals/ImageGeneratorModal.jsx'
const react_components = {PageCollection, Tagging, ImageGeneratorModal}



function turboLoad(e){
  handleComponent(e)
}

const handleComponent = (e)=>{
  document.querySelectorAll('[data-component]').forEach((node) => {
    let json = ""
    if (node.dataset.json != undefined){
      json = node.dataset.json
    }
    createApp(components[node.dataset.component],{json: json}).mount(node)
  })

  document.querySelectorAll('[data-react-component]').forEach((node) => {
    const root = createRoot(node)
    root.render(
      React.createElement(react_components[node.dataset.reactComponent], {data: node.dataset}, null)
    )
    //root.render(`<HelloReact name="Reacty" />`)
  })
}

const listenTurbo = ()=>{  
  document.removeEventListener("turbo:load", turboLoad)
  document.addEventListener("turbo:load", turboLoad)
}

export {listenTurbo, handleComponent}

