import { Controller } from 'stimulus'
export default class extends Controller {
  static targets = ["frame"]

  connect(){
    console.log('embed-app connected')
    window.addEventListener('message', (e)=>{
      if (e.data.cmd == 'init'){
        let message = {
          cmd: 'setup',
          site: {
            name: this.frameTarget.getAttribute('data-site-name')
          }
        }
        this.frameTarget.contentWindow.postMessage(message, '*')
      }else if (e.data.cmd == 'get_authorization_token'){
        fetch(e.data.url, {
          method: 'GET',
          headers: {
            'Content-Type':  'application/json'
          }
        }).then(response => response.json()).then((json)=>{
          this.frameTarget.contentWindow.postMessage({
            cmd: 'set_logged',
            params: json.params
          }, '*')
        })
      }else if (e.data.cmd == 'get_session_token') {
        fetch('/session_tokens', {
          method: 'POST',
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({site_name: e.data.site_name, api_key: e.data.api_key})
        }).then(request=>request.json()).then((json)=>{
          this.frameTarget.contentWindow.postMessage({
            cmd: 'session_token_ready',
            token: json.token
          }, '*')
        })
      }else if (e.data.cmd == 'setHeight') {
        this.frameTarget.style.height = e.data.height
      }
    })
  }
}