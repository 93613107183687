import React, { useState, useEffect } from 'react'

const Tagging = function(props){
  
  let inputTags = (props.data.tags.trim() == '')? [] : props.data.tags.split(",").map(x=>x.trim())
  
  const [tags, setTags] = useState(inputTags)

  const deleteTag = (tag)=>{
    setTags((ori)=>{      
      let index = ori.findIndex(x=>x==tag)
      let newTags = [...ori]
      newTags.splice(index, 1)
      publishChange(newTags)
      return newTags
    })
  }

  const addTag = (event)=>{
    if (event.keyCode == 32) { //space      
      if (event.target.value.trim() != '') {
        setTags((ori)=>{
          let newTags = [...ori]
          let tag = event.target.value.trim()          
          if (newTags.findIndex(x=>x == tag) == -1){
            event.target.value = ''
            newTags.push(tag)     
            publishChange(newTags)
            return newTags
          }else{
            return [...ori]
          }    
        })        
      }
    }
  }

  const publishChange=(tags)=>{    
    let event_name = (props.data.eventChange == undefined)? "tag-change" : props.data.eventChange
    
    let event = new CustomEvent(event_name, {
      detail: tags
    })
    window.dispatchEvent(event)
  }

  return(
    <div>
      <input type="text"  className='border' placeholder='Spasi untuk membuat tag'
        onKeyUp={(e)=>addTag(e)}/> 
      
      <div className='flex mt-4'>
        {
          tags.map((tag)=>{
            return(
              <div key={tag} className='text-sm ml-2 border rounded-full bg-gray-200 py-1 px-2 text-gray-600'>
                <div>
                  {tag}
                  <span onClick={()=>deleteTag(tag)} className='cursor-pointer'> x</span>
                </div>              
              </div>
            )
          })
        }        
      </div>
    </div>
  )
}
export default Tagging