import React, { useState } from 'react'

export default function ImageGeneratorModal(props) {  
  
  const [inputPrompt, setInputPromp] = useState("")
  const [image, setImage] = useState(null)
  const [loading, setLoading] = useState(false)
  const [show, setShow] = useState(false)
  const [selectedFile, setSelectedFile] = useState(undefined)

  const genImage = ()=>{
    let token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    setLoading(true)
    fetch(`${props.data.generateImageUrl}`, {
      method: 'POST',
      credentials: "include",
      headers: {
        'X-CSRF-Token': token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({"prompt": inputPrompt})
    }).then(response=>response.json()).then(json=>{
      setLoading(false)
      if (json['error']){
        alert(json.error.message)
      }else{
        console.log(json)
        if (json.data[0]){
          setImage(json.data[0].url)
        }
      }    
    })
  }

  const buildImage=()=>{
    if (image != null && loading == false){
      return (
        <div>
          <img src={image} className='w-62 h-60'></img>
        </div>
      )
    }
  }

  const buildGenerateButton = ()=>{
    if (loading){
      return (
        <input type="button" value="generating...." className='mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-white bg-green-500 shadow-sm ring-1 ring-inset ring-gray-300 sm:mt-0 sm:w-auto' disabled />
      )
    }else{
      return (
        <input type="button" value="Generate" className='lg:ml-4 mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-white bg-green-500 shadow-sm ring-1 ring-inset ring-gray-300 sm:mt-0 sm:w-auto' onClick={()=>genImage()}/>
      )
    }
  }

  const buildUseButton = () => {
    if (image){
      return (
        <button type="button" onClick={()=>{useImage()}} className="inline-flex w-full justify-center rounded-md bg-green-500 px-3 py-2 text-sm font-semibold text-white shadow-sm  sm:ml-3 sm:w-auto">Gunakan</button>
      )
    }
  }

  const useImage = () => {
    setShow(false)
    let token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    fetch(`${props.data.updateImageUrl}`, {
      method: 'PATCH',
      credentials: "include",
      headers: {
        'X-CSRF-Token': token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({"image_url": image})
    }).then(response=>response.json()).then(json=>{      
      let event = new CustomEvent('image-changed', {
        detail: {url: image}
      })
      window.dispatchEvent(event)
    })

  }

  

  const buildModal = () => {
    return (
    <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
 
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          
          <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">                
                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left w-full">
                  <h3 className="text-base font-semibold leading-6 text-gray-900" id="modal-title">{props.title}</h3>
                  <div className="mt-2">
                    <div className="text-sm text-gray-500">{props.children}</div>
                    <div className="text-sm text-gray-500">Ketik deskripsi gambar yang akan di generate</div>
                    <div className="text-sm text-gray-500">Contoh: "mobil merah di tempat parkir"</div>
                    <div className='m-6 border-2'>
                      <input className='w-full border text-xl' type="text" onInput={(e)=>{setInputPromp(e.target.value)}} placeholder='ketik gambar yg mau digenerate'/>
                    </div>                    
                    <div>
                      {buildImage()}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
              {buildUseButton()}
              {buildGenerateButton()}               
              <button type="button" onClick={()=>{setShow(false)}} className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    )
  }

  if (show){
    return buildModal()
  }

  return (
    <div className=''>      
      <input type="button" className='text-white bg-green-500 px-3 py-2 rounded' value="Gunakan AI untuk generate gambar?" onClick={()=>{setShow(true)}}/>
    </div>
  )
}