import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['hidden']

  connect(){
    console.log('tag-listen connected')    
  }
  update(e){
    this.hiddenTarget.value = e.detail.join(",")    
  }
}