// Entry point for the build script in your package.json
require("@hotwired/turbo-rails")

import * as ActiveStorage from "@rails/activestorage"
import "./channels"

// import "./stylesheets/application"
import './controllers'

import { createApp } from 'vue'

ActiveStorage.start()

import {listenTurbo} from "./common/component_loader"

listenTurbo()
