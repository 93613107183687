import { Controller } from 'stimulus'; 
export default class extends Controller {
  static targets = ["selector", "button"]
  connect(){
    console.log('auth_link_app connected')
  }

  updateSite(e){
    e.preventDefault()
    let href = this.buttonTarget.href
    let url = new URL(href)
    let paths = url.pathname.split("/")
    paths[1] = e.currentTarget.value
    url.pathname = paths.join("/")
    this.buttonTarget.href = url.toString()
  }
}