import { Controller } from 'stimulus'
export default class extends Controller {
  connect() {

  }

  reload(e) {
    let url = e.currentTarget.getAttribute('data-location').replace(':id', e.currentTarget.value)
    location.href = url    
  }
}