import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import BrowseModal from './modals/BrowseModal.jsx'
import ConfirmModal from './modals/ConfirmModal.jsx'

const PageCollection = function PageCollection(props) {
  let hash = JSON.parse(props.data.json)

  const [pages, setPages] = useState([])
  const [assignedPages, setAssignedPages] = useState([])
  const [selectedPages, setSelectedPages] = useState([])
  const [showBrowseModal, setShowBrowseModal] = useState(false)

  useEffect(()=>{
    console.log('effect')
    fetchAssignedPages()    
  },[])

  const browsePages=()=>{
    fetchPages()
    setShowBrowseModal((ori)=>true)
  }

  const closeBrowsePages=()=>{
    setShowBrowseModal((ori)=>false)
  }



  const fetchPages = ()=>{
    let token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    fetch(props.data.pagesUrl, {
      method: 'get',
      headers: {
        'X-CSRF-Token': token
      }
    }).then(response=>response.json()).then(json=>{
      setPages((ori)=>{
        return json.pages
      })
    })
  }

  const fetchAssignedPages = ()=>{
    let token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    fetch(props.data.assignedPagesUrl, {
      method: 'get',
      headers: {
        'X-CSRF-Token': token
      }
    }).then(response=>response.json()).then(json=>{
      setAssignedPages((ori)=>{
        return json.pages
      })
    })
  }

  const insertToCollection = ()=>{
    let token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    let params = {
      page_ids: selectedPages.map((x)=>x.id)
    }
    
    fetch(props.data.insertPagesUrl, {
      method: 'POST',
      credentials: "include",
      headers: {
        'X-CSRF-Token': token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(params)
    }).then(response=>response.json()).then(json=>{
      closeBrowsePages()
      fetchAssignedPages() 
    })
  }

  const removePage = (page)=>{
    let token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')    
    fetch(`${props.data.removePageUrl}?page_id=${page.id}`, {
      method: 'DELETE',
      credentials: "include",
      headers: {
        'X-CSRF-Token': token,
        'Content-Type': 'application/json'
      }
    }).then(response=>response.json()).then(json=>{
      fetchAssignedPages()
    })
  }

  const selectionChanged = (event, page)=>{
    if (event.currentTarget.checked) {
      setSelectedPages((ori)=>{
        let cloned = [...ori]
        cloned.push({...page})
        return cloned
      })
    }else{
      setSelectedPages((ori)=>{        
        let cloned = [...ori]
        let index = cloned.findIndex((x)=>x.id == page.id)
        cloned.splice(index, 1)        
        return cloned
      })
    }
  }

  const isSelected = (page)=>{
    return selectedPages.findIndex((x)=> x.id == page.id) != -1
  }

  const PageList = ()=>{
    return(
      <div>        
        <table>        
          <tbody>
            {pages.map((page)=>{
              return(
                <tr key={page.id}>
                  <td className='w-full'>{page.title}</td>
                  <td><input type="checkbox" checked={isSelected(page)} onChange={(event)=>selectionChanged(event, page)}/></td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }

  const emit=(name, page) => {
    let event = new CustomEvent(name, {
      detail: page
    })
    window.dispatchEvent(event)
  }

  const AssignedPageList = ()=>{
    return(
      <div className="mt-4">
        <h2 className="text-lg">Halaman dalam koleksi '{hash.collection.title}'</h2>
        <table className="mt-4">
          <thead>
            <tr>
              <th>Nama Koleksi</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {assignedPages.map((page)=>{
              return(
                <tr key={page.id}>
                  <td>{page.title}</td>
                  <td className="pl-4">
                    <button onClick={()=>{emit("ask-delete-page", page)}} className='bg-red-600 text-white rounded px-2 py-1 text-sm'>X</button>                    
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }

  
  return(
    <div>
      <button onClick={()=>browsePages()} className="bg-green-500 px-3 py-2 rounded text-white">Tambahkan Halaman</button>
      <BrowseModal show={showBrowseModal} title="Browse Halaman" onOk={()=>insertToCollection()} onCancel={()=>closeBrowsePages()}>
        <PageList></PageList>
      </BrowseModal>
      <AssignedPageList/>
      <ConfirmModal trigger-event-name="ask-delete-page" onOk={(page)=>removePage(page)}>Yakin?</ConfirmModal>
    </div>
  )
}

export default PageCollection