import { Controller } from 'stimulus'; 
export default class extends Controller {
  static targets = ["button", "photo", "marker"]
  

  connect(){
    console.log('delete_popup connected')
  }

  markDelete(e){
    e.preventDefault()
    this.buttonTarget.classList.add('hidden')
    this.photoTarget.classList.add('hidden')
    this.markerTarget.value = "1"
  }

  replaceImage(e){
    this.photoTarget.src = e.detail.url
    this.photoTarget.classList.remove('hidden')
    this.buttonTarget.classList.remove('hidden')
    this.markerTarget.value = "0"
  }
}