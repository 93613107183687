import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["submit"]

  connect(){
    console.log('reloader connected')
  }

  reload(e){
    e.preventDefault()
    let url = e.detail.url
    let method = e.detail.method

    this.element.querySelector("form").action = url
    this.element.querySelector("form").method = method
    console.log('now reloading..')
    this.submitTarget.click()
  }

  
}