import { Controller } from 'stimulus'
export default class extends Controller {
  connect() {
    console.log('delete-confirmation connected')
  }

  askDelete(e) {
    e.preventDefault()
    let message = e.currentTarget.getAttribute('data-confirm')
    if (confirm(message)){      
      e.currentTarget.form.submit()
    }
    
  }
}